.partneram__banner__background {
    width: 100%;
    height: 20rem;
    background: url("./assets/background.jpg") no-repeat center center;
    background-size: cover;
    order: 0;
}

.partners {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.partners__image {
    width: 12rem;
    height: auto;
    margin: 2rem 3rem;
}

.advs {
    display: flex;
    justify-content: space-between;
}

.adv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 4em 1em;
    max-width: 25%;
}

.adv__image {
    width: 5rem;
    height: auto;
}

.adv__caption {
    color: #007EAD;
    font-size: 1em;
    line-height: 1.125em;
    font-weight: bold;
    text-align: center;
    margin-top: 1em;
}

.block__half {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 2rem 0;
    align-self: center;
}

.block__half--green {
    background-color: rgb(0, 170, 144);
    padding: 2rem;
}

.block__half__image {
    width: 12em;
    margin: 0 2rem;
    height: auto;
}

.block__half__title {
    text-transform: uppercase;
    color: white;
    font-size: 1.5em;
    line-height: 1.875em;
}

.block__half__numbers {
    display: flex;
    flex-direction: column;
}

.block__half__numbers__wrap {
    display: flex;
    width: 100%;
    align-items: center;
}

.block__half__numbers__number {
    font-size: 4em;
    color: white;
    margin-right: .25em;
}

.block__half__numbers__text {
    color: white;
    font-size: 1em;
}

.block__half__sign {
    color: white;
    align-self: flex-start;
}

@media (min-width: 62rem) {

    .block__half__numbers {
        flex-direction: row;
    }

    .block__half__numbers__wrap {
        width: 50%;

    }

    .partneram__banner__background {
        width: 45%;
        height: 100%;
        order: 1;
    }

    .block__half {
        width: 50%;
    }

    .block__half__image {
        max-width: 16rem;
    }
}