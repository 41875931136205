.kvartira__banner {
    width: 100%;
    height: auto;
    flex-direction: column;
    display: flex;
}

.kvartira__banner__description {
    background: rgb(239, 114, 115);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    order: 1;
    flex-direction: column;
    padding-bottom: 2rem;
}

.kvartira__banner__background {
    width: 100%;
    height: 20rem;
    background: url("./assets/background.png") no-repeat;
    background-size: cover;
    order: 0;
}

.kvartira__banner__description__content {
    border: 2px solid rgba(255, 255, 255, .4);
    border-radius: 13px;
    padding: 2rem;
    margin: 2rem;
    max-width: 80%;
}

.kvartira__banner__title {
    color: white;
    font-size: 1.5em;
    font-weight: bold;
    line-height: 1em;
    margin: 0;
    text-transform: uppercase;
}

.kvartira__banner__list {
    padding: 0;
    list-style: none;
}

.kvartira__banner__list > li, .kvartira__banner__text {
    color: white;
    font-size: 1.125em;
    text-transform: uppercase;
    display: flex;
    align-items: flex-start;
    margin: .5rem 0;
    font-weight: bold;
}

.kvartira__banner__text {
    margin: 1rem 0;
}

.kvartira__banner__list > li img {
    width: 2rem;
    margin-right: .5rem;
}

.kvartira__block--grey {
    background-color: rgb(243, 243, 243);
    padding: 4rem 1rem;
}

.kvartira__text {
    margin: 2rem 0;
    padding: 1rem 2rem;
}

.kvartira__list {
    margin: 0 auto;
    list-style: none;
    font-size: 1em;
    color: rgb(239, 114, 115);
    font-weight: bold;
    padding: 1rem 2rem;
}

.kvartira__list > li:not(:last-child) {
    margin-bottom: 1rem;
}

.kvartira__list > li img {
    width: 1.33rem;
    margin-right: .5rem;
}

.kvartira__block--red {
    background-color: rgb(239, 114, 115);
    padding: 4rem 1rem;
    margin: 4rem 0;
    color: white;
}

.kvartira__block__title--white {
    color: white;
    font-size: 1.5em;
    font-weight: bold;
    line-height: 1.5em;
    text-align: center;
}

.kvartira__columns {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: column;
}

.kvartira__column {
    width: 100%;
    display: flex;
    padding: 1rem 2rem;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.kvartira__column__number {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 2.25em;
    font-weight: bold;
    border: 1px solid white;
    border-radius: 50%;
    text-align: center;
    padding: 2rem;
    height: 3.25rem;
    width: 3.25rem;
    margin-right: 1rem;
}

.kvartira__simple-list {
    list-style: disc;
    line-height: 1.4em;
}

.kvartira__text--red {
    color: rgb(239, 114, 115);
    padding: 1rem 2rem;
}

@media (min-width: 62rem) {

    .kvartira__banner {
        height: 30rem;
        flex-direction: row;
    }

    .kvartira__banner__description {
        width: 55%;
        order: 0;
    }

    .kvartira__banner__background {
        width: 45%;
        height: 100%;
        order: 1;
    }

    .kvartira__banner__title {
        font-size: 2.25em;
    }

    .kvartira__list {
        margin: 2rem auto;
        max-width: 75%;
        padding: 1rem;
        font-size: 1.25em;
    }

    .kvartira__columns {
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: row;
    }

    .kvartira__column {
        width: 38%;
        display: flex;
        margin: 0 2rem;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        flex-direction: row;
    }

}