* {
    box-sizing: border-box;
}

@font-face {
    font-family: 'CeraRoundPro';
    src:
            local('CeraRoundPro-Regular'),
            url('./fonts/CeraRoundPro-Regular.woff2') format('woff2'),
            url('./fonts/CeraRoundPro-Regular.woff') format('woff'),
            url('./fonts/CeraRoundPro-Regular.otf') format('otf');
    font-style: normal;
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CeraRoundPro';
    src:
            local('CeraRoundPro-Bold'),
            url('./fonts/CeraRoundPro-Bold.woff2') format('woff2'),
            url('./fonts/CeraRoundPro-Bold.woff') format('woff'),
            url('./fonts/CeraRoundPro-Bold.otf') format('otf');
    font-style: normal;
    font-weight: bold;
    font-display: swap;
}

body {
    margin: 0 auto;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.wf-active body {
    font-family: 'CeraRoundPro', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.button {
    background-color: rgb(238, 238, 238);
    border-radius: 29px;
    border: 1px solid transparent;
    color: black;
    font-size: 1em;
    line-height: 1em;
    padding: 1.25rem 4.5rem;
    cursor: pointer;
    font-weight: bold;
    transition: background-color .25s ease-out;
    text-decoration: none;
}

.button:disabled {
    cursor: not-allowed;
}

.button--blue {
    background-color: rgb(54, 138, 186);
    color: white;
}

.button--blue:hover {
    background-color: rgb(1, 69, 127);
}

.button--red {
    background-color: rgb(239, 114, 115);
    color: white;
}

.button--red:hover {
    background-color: rgb(183, 8, 10);
}

.button--green {
    background-color: rgb(0, 170, 144);
    color: white;
}

.button--green:hover {
    background-color: rgb(54, 138, 186);
}

.mt-10 {
    margin-top: 10rem !important;
}

.mt-10--not-mobile {
    margin-top: 0 !important;
}

.plus:after {
    content: '\02795';
    font-size: .875em;
    color: rgb(54, 138, 186);
}

.active:after {
    content: "\2796";
}

.qa {
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
}

.qa__row {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid rgba(196, 196, 196, .2);
    padding: 2rem 0 0;
    margin: 0 2rem;
    align-items: center;
    cursor: pointer;
}

.qa__content {
    padding: 0 1rem;
    background-color: white;
    display: none;
    overflow: hidden;
    margin: 0 2rem;
}

.not-mobile {
    display: none;
}

.force-bottom-border {
    border-bottom: 2px solid rgba(196, 196, 196, .2);
}

.mt-25--not-mobile {
    margin-top: 1rem;
}

.flex--custom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

@media (min-width: 62rem) {

    .flex--custom {
        flex-direction: row;
    }

    .qa__row {
        margin: 0 5rem;
    }

    .qa__content {
        margin: 0 5rem;
    }

    .not-mobile {
        display: block;
    }

    .force-bottom-border {
        border-bottom: 0;
    }

    .mt-25--not-mobile {
        margin-top: 2.5rem;
    }

    .mt-10--not-mobile {
        margin-top: 10rem !important;
    }

}