.not-found {
    justify-content: center;
    align-items: center;
    display: flex;
}

.not-found__block {
    padding-top: 6em;
    width: auto;
    min-width: 60em;
    height: 100%;
    text-align: center;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.not-found__block h1 {
    color: rgb(0, 170, 144);
    font-size: 6em;
    margin-bottom: .25em;
}

.not-found__block p {
    font-size: 1.25em;
    max-width: 20em;
    line-height: 1.26;
}

.not-found__block a {
    color: black;
}