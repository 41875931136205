.post__content {
    width: 100%;
    max-width: 75em;
    margin: 0 auto;
    padding: 0 1em;
}

.post__title {
    font-size: 2em;
    text-align: center;
    margin-top: 2em;
}

.post__row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.post__row__splash {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2em 0;
}

.post__row__splash p {
    background-color: rgb(100, 165, 139);
    padding: 1.5em 2em;
    border-radius: 3.33em;
    font-weight: bold;
    color: white;
    font-size: 1.5em;
    text-align: center;
}

.post__row__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.post__row__item img {
    width: 100%;
    height: auto;
    max-width: 10em;
}

.post__row__item figcaption {
    margin-top: 1em;
    font-weight: bold;
    font-size: 1.25em;
}

.post__row__item p {
    text-align: center;
}

.row__header {
    display: flex;
    align-items: center;
}

.row__header h2 {
    color: #007EAD;
    margin-left: 1em;
    font-size: 2em;
}

.row__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
}

.post__grid__item {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;
}

.post__grid__item img {
    width: auto;
    max-width: 10em;
    height: auto;
    max-height: 5em;
    margin: 0 auto 1em;
}

.post__grid__item figcaption {
    text-align: center;
}

.post__row--img {
    display: flex;
    width: 100%;
    justify-content: center;
    max-height: 20em;
}

.post__row__img {
    width: 100%;
    max-width: 60em;
    margin: 2em 0 auto;
    height: auto;
}

.post__header {
    display: flex;
    margin: 4em auto;
    flex-direction: column;
}

.post__header h1 {
    color: #007EAD;
    font-size: 2em;
}

.post__header__image {
    max-width: 100%;
    width: 25em;
    height: auto;
    margin-right: 2em;
}

@media (min-width: 62em) {

    .post__header {
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .post__row {
        flex-direction: row;
    }

    .post__grid__item {
        max-width: calc(30% - 4em);
    }

    .post__row--img {
        max-height: 100%;
    }
}
