.kasko__banner__background {
    width: 100%;
    height: 20rem;
    background: url("./assets/background.jpg") no-repeat center center;
    background-size: cover;
    order: 0;
}

.kasko__block__title {
    color: rgb(239, 114, 115);
    font-weight: bold;
    font-size: 1.5em;
    text-align: center;
}

.kasko__columns {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: column;
}

.kasko__column {
    width: 100%;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.kasko__column__number {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: rgb(239, 114, 115);
    font-size: 2.25em;
    font-weight: bold;
    line-height: 2.5em;
    border: 1px solid rgb(239, 114, 115);
    border-radius: 50%;
    text-align: center;
    padding: .5rem;
    height: 3.75rem;
    width: 3.75rem;
}

@media (min-width: 62rem) {

    .kasko__banner__background {
        width: 45%;
        height: 100%;
        order: 1;
    }

    .kasko__block__title {
        font-size: 2.25em;
    }

    .kasko__columns {
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }

    .kasko__column {
        width: calc(30% - 4rem);
        margin: 0 2rem;
        align-items: flex-start;
        padding: 0;
    }

}