.zdorove__banner__title {
    color: white;
    font-size: 1.5em;
    font-weight: bold;
    line-height: 1.5em;
    margin: 0;
    text-transform: uppercase;
}

.zdorove__banner__description {
    background: rgb(54, 138, 186);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    order: 1;
    flex-direction: column;
    padding-bottom: 2rem;
}

.zdorove__banner__background {
    width: 100%;
    height: 20rem;
    background: url("./assets/background.jpg") no-repeat center center;
    background-size: cover;
    order: 0;
}

.zdorove__banner__description__content {
    border: 2px solid rgba(255, 255, 255, .4);
    border-radius: 13px;
    padding: 2rem;
    margin: 2rem;
    max-width: 75%;
}

.zdorove__title {
    color: rgb(54, 138, 186);
    text-align: center;
    font-size: 2.25em;
}

.zdorove__columns {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
}

.zdorove__column {
    width: 100%;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.zdorove__column--row {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem 2rem;
    align-items: center;
    text-align: center;
}

.zdorove__column--row img {
    width: 8rem;
    height: auto;
}

@media (min-width: 62rem) {

    .zdorove__banner {
        height: 30rem;
        flex-direction: row;
    }

    .zdorove__banner__description {
        width: 55%;
        order: 0;
    }

    .zdorove__banner__background {
        width: 45%;
        height: 100%;
        order: 1;
    }

    .zdorove__banner__title {
        font-size: 2.25em;
    }

    .zdorove__columns {
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .zdorove__column {
        width: 20%;
        margin: 0 1rem;
        align-items: flex-start;
    }

    .zdorove__column--row {
        width: calc(50% - 8rem);
        margin: 0 1rem;
        text-align: left;
        flex-direction: row;
    }

}