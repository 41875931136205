.home__block {
    margin: 4em auto;
    width: 100%;
}

.home__advantages {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.home__banner__background {
    width: 100%;
    height: 20rem;
    background: url("./assets/sdcs.png") no-repeat center center;
    background-size: cover;
    order: 0;
}

.creds {
    display: flex;
    width: 100%;
    max-width: 19rem;
    justify-content: center;
    margin: 2rem auto;
    line-height: 1.296rem;
}

.advantage {
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
}

.advantage img {
    width: 6rem;
    height: auto;
}

.advantage p {
    text-transform: uppercase;
    text-align: center;
    font-size: .875em;
}

.home__subtitle {
    color: rgb(88, 89, 91);
    font-size: 1em;
}

.home__feedback {
    min-height: 36rem;
    background: url('./assets/smile.jpg') no-repeat center bottom;
    background-size: contain;
    padding: 0 0 8rem;
    display: flex;
    margin-bottom: 4rem;
}

.home__feedback__image {
    display: none;
}

.home__feedback__form {
    margin: 0 auto;
    padding: 0 2rem;
}

.feedback__modal {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    display: none;
    justify-content: center;
    align-items: center;
}

.home__feedback__form--modal {
    margin: 0 auto;
    padding: 2rem;
    background-color: #fefefe;
    flex-direction: column;
    width: 26rem;
    max-width: 100%;
    display: flex;
}

.feedback__modal--show {
    display: flex;
}

.home__feedback__header {
    display: flex;
    justify-content: space-between;
}

.home__feedback__title--modal {
    color: rgb(0, 170, 144);
    font-size: 2em;
    margin-bottom: 0;
}

.feedback__annotation {
    text-align: center;
    display: none;
}

.feedback__annotation--success {
    color: forestgreen;
    display: block;
}

.feedback__annotation--error {
    color: darkred;
    display: block;
}

.close {
    color: #aaa;
    float: right;
    font-size: 2em;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.home__feedback__title {
    color: rgb(0, 170, 144);
    font-size: 3em;
    text-align: center;
    margin-bottom: 0;
}

.home__feedback__subtitle {
    color: rgb(45, 45, 45);
    font-size: 1em;
    text-align: center;
}

.home__feedback__form form, .home__feedback__form--modal form {
    display: flex;
    flex-direction: column;
}

.home__feedback__form form input, .home__feedback__form form textarea, .home__feedback__form--modal form input {
    border-radius: 10px;
    border: 2px solid rgb(0, 170, 144);
    padding: 1rem 1.5rem;
    font-size: .875em;
    margin: .5rem 0;
}

.button--feedback {
    width: 50%;
    padding: 1rem;
    margin: 1.25rem auto 0;
}

.home__map {
    width: 100%;
    height: 20rem;
}

@media (min-width: 62rem) {

    .home__advantages {
        flex-wrap: nowrap;
    }

    .advantage img {
        width: 8rem;
    }

    .advantage p {
        font-size: 1em;
    }

    .home__feedback__image {
        background: url('./assets/note.jpg') no-repeat center center;
        height: inherit;
        width: 50%;
        background-size: cover;
        display: block;
    }

    .home__map {
        height: 30rem;
    }

    .home__feedback__form {
        padding: 0;
    }

}