.footer {
    width: 100%;
    background-color: rgb(45, 49, 51);
    padding: 2.5rem 1rem 1rem;
    order: 2;
    flex: 0 1 auto;
}

.footer__inner {
    max-width: 75rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.footer__logo-link {
    order: 3;
    margin: 1rem 0;
}

.footer__logo {
    width: 7em;
    height: auto;
}

.footer__navigation {
    display: none;
}

.footer__navigation--mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    order: 2;
}

.footer__navigation__block {
    display: flex;
    flex-direction: row;
    padding: .5rem 0;
}

.footer__navigation__block > a:not(:last-child) {
    margin-right: 1.5rem;
}

.footer__navigation__link {
    color: white;
    text-decoration: none;
    font-size: .875em;
    line-height: 1.25em;
}

.footer__phone {
    display: flex;
    align-items: center;
    order: 0;
    margin-bottom: 1rem;
}

.footer--first-delimiter {
    order: 1;
}

.footer--second-delimiter {
    order: 4;
}

.footer__phone__number {
    display: inline-block;
    color: white;
    text-decoration: none;
    font-size: 1.5em;
    line-height: 1.5em;
    font-weight: bold;
}

.footer__copyright {
    font-size: .75em;
    color: rgba(255, 255, 255, .5);
    align-self: center;
    text-align: center;
    margin-bottom: 0;
}

.footer__info-link {
    font-size: 1em;
    align-self: center;
    text-align: center;
    margin-bottom: 0;
}

.footer__info-link a {
    color: rgba(250, 250, 250);
    text-decoration: none;
}

.footer__copyright__link {
    color: rgba(255, 255, 255, .5);
    text-decoration: none;
}

.footer__copyright__link:hover {
    color: white;
}

.footer__social {
    margin: 0 1em;
}

.footer__social__image {
    width: 3em;
    height: auto;
}

@media (min-width: 62rem) {

    .footer__inner {
        justify-content: space-between;
        flex-direction: row;
    }

    .footer__navigation--mobile {
        display: none;
    }

    .footer__navigation {
        display: flex;
        justify-content: space-around;
        flex: 1 1 auto;
        order: 1;
    }

    .footer__logo-link {
        order: 0;
        margin: 0;
    }

    .footer__phone {
        display: flex;
        align-items: center;
        order: 2;
        margin-bottom: 0;
    }

    .footer--first-delimiter, .footer--second-delimiter {
        display: none;
    }

    .footer__navigation__block {
        flex-direction: column;
        padding: 0;
    }

}