.puteshestviya__banner {
    width: 100%;
    height: auto;
    flex-direction: column;
    display: flex;
}

.puteshestviya__banner__description {
    background: rgb(0, 126, 173);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    order: 1;
    flex-direction: column;
    padding-bottom: 2rem;
}

.puteshestviya__banner__background {
    width: 100%;
    height: 20rem;
    background: url("./assets/background.jpg") no-repeat center center;
    background-size: cover;
    order: 0;
}

.puteshestviya__banner__description__content {
    border: 2px solid rgba(255, 255, 255, .4);
    border-radius: 13px;
    padding: 2rem;
    margin: 2rem;
    max-width: 80%;
}

.puteshestviya__banner__title {
    color: white;
    font-size: 1.5em;
    font-weight: bold;
    line-height: 1em;
    margin: 0;
    text-transform: uppercase;
}

.puteshestviya__banner__list {
    padding: 0;
    list-style: none;
}

.puteshestviya__banner__list > li, .puteshestviya__banner__text {
    color: white;
    font-size: 1.125em;
    text-transform: uppercase;
    display: flex;
    align-items: flex-start;
    margin: .5rem 0;
    font-weight: bold;
}

.puteshestviya__banner__text {
    margin: 1rem 0;
}

.puteshestviya__banner__list > li img {
    width: 2rem;
    margin-right: .5rem;
}

.puteshestviya__title {
    color: rgb(88, 89, 91);
    font-size: 1.5em;
    text-align: center;
}

.puteshestviya__column__number {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: rgb(54, 138, 186);
    font-size: 2.25em;
    font-weight: bold;
    line-height: 2.5em;
    border: 1px solid rgb(54, 138, 186);
    border-radius: 50%;
    text-align: center;
    padding: .5rem;
    height: 3.75rem;
    width: 3.75rem;
}

.puteshestviya__column__title {
    color: rgb(54, 138, 186);
    font-size: 1em;
    line-height: 1em;
}

.puteshestviya__title--blue {
    color: rgb(54, 138, 186);
    font-size: 1.5em;
    text-align: center;
}

.puteshestviya__block {
    margin-bottom: 3rem;
}

.puteshestviya__block__title, .puteshestviya__block__text {
    color: rgb(45, 45, 45);
    font-size: 1em;
    padding: 1rem 2rem;
}

.puteshestviya__block__text {
    background: rgba(243, 243, 243, 0.5);
    border-radius: 10px;
    padding: 3rem;
}

.delimiter--puteshestviya {
    margin-top: 0;
    box-shadow: none;
    border: 1px solid rgba(196, 196, 196, 0.2);
}

.puteshestviya__block--center {
    display: flex;
    justify-content: center;
}

@media (min-width: 62rem) {

    .puteshestviya__banner {
        height: 30rem;
        flex-direction: row;
    }

    .puteshestviya__banner__description {
        width: 55%;
        order: 0;
    }

    .puteshestviya__banner__background {
        width: 45%;
        height: 100%;
        order: 1;
    }

    .puteshestviya__banner__title {
        font-size: 2.25em;
    }

    .puteshestviya__block__title, .puteshestviya__block__text {
        padding-left: 3rem;
    }

}