.header {
    width: 100%;
    background-color: rgb(45, 49, 51);
    padding: 1rem;
    order: 0;
    flex: 0 1 auto;
}

.header__logo__wrapper {
    display: flex;
    align-items: center;
}

.header__inner {
    max-width: 75rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.header__logo {
    width: 4.5rem;
    height: auto;
}

.header__navigation {
    display: none;
}

.header__navigation__link {
    color: white;
    text-decoration: none;
    font-size: 1em;
    line-height: 1.5em;
}

.header__navigation__link:not(:last-child) {
    margin-right: 1.5em;
}

.header__phone {
    display: flex;
    align-items: center;
}

.header__phone__icon {
    width: 2rem;
    height: 2rem;
}

.header__phone__number {
    display: none;
}

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
    background-color: white !important;
}

.hamburger {
    display: inline-block !important;
}

.header__navigation--mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.header__navigation--mobile-open {
    display: none;
}

.header__navigation__link--mobile {
    color: white;
    text-decoration: none;
    font-size: 1.25em;
    line-height: 2em;
}

@media (min-width: 62rem) {

    .header__navigation {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .header__phone__number {
        display: inline-block;
        color: white;
        text-decoration: none;
        font-size: 1.5em;
        line-height: 1.5em;
        font-weight: bold;
    }

    .header__phone__icon {
        width: 1.5rem;
        height: 1.5rem;
    }

    .hamburger {
        display: none !important;
    }

    .header__navigation--mobile {
        display: none;
    }

}