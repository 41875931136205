.app {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.content {
    order: 1;
    flex: 1 1 auto;
    width: 100%;
    margin: 0 auto;
}

.block {
    width: 100%;
    max-width: 75rem;
    margin: 0 auto;
}