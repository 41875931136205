.to__banner__background {
    width: 100%;
    background: url("./assets/background.jpg") no-repeat center center;
    background-size: cover;
    order: 0;
}

.to__block__title {
    color: rgb(45, 45, 45);
    font-size: 1.2em;
    padding: 1rem 0;
    font-weight: bold;
    text-decoration: none;
    margin: 0 auto;
}

.delimiter--to {
    margin-top: 1rem;
    box-shadow: none;
    border: 1px solid rgba(196, 196, 196, 0.2);
}

.to__block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.to__block--center {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
}

.to__white {
    color: white;
}

@media (min-width: 62rem) {
    .to__block {
        flex-direction: row;
    }
}