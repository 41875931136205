.blog__posts {
    width: 100%;
    max-width: 75em;
    margin: 5em auto;
}

.blog__post {
    display: flex;
    flex-direction: column;
}

.blog__post__image {
    width: 10em;
    height: auto;
    margin: 0 auto;
}

.blog__post__content {
    display: flex;
    flex-direction: column;
}

.blog__post__title {
    margin-bottom: .25em;
}

.blog__post__description {
    margin-top: .25em;
}

.blog__post__wrapper {
    color: black;
    text-decoration: none;
}

@media (min-width: 62em) {
    .blog__post {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    .blog__post__image {
        margin: 0 1em 0 0;
    }
}